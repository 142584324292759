<template>
  <div>
    <h2 class="is-size-3">
      Vakkenoverzicht {{ niveau }}
    </h2>
    <p>
      Selecteer de vakken die je wilt volgen in de onderstaande dropdown.
      Je kunt vakken verwijderen met het kruisje naast de naam van het vak.
    </p>
    <ValidatedFormField
      :validator="$v.data.vakken"
      @input="() => $v.data.vakken.$touch()"
    >
      <Multiselect
        v-model="data.vakken"
        label="naam"
        track-by="naam"
        :options="beschikbareVakken"
        :close-on-select="maxVakken === 1"
        :max="maxVakken"
        placeholder="Kies hier je vak(ken)"
        select-label="Kies vak"
        selected-label="Gekozen"
        deselect-label="Verwijder"
        multiple
        @remove="verwijderVak"
      >
        <template #tag="{ option, remove }">
          <span :class="tagClass(option)">
            <span v-text="option.naam" />
            <i
              v-if="!heeftInschrijving(option)"
              class="multiselect__tag-icon"
              tabindex="1"
              @keypress.enter.prevent="() => remove(option)"
              @mousedown.prevent="() => remove(option)"
            />
          </span>
        </template>
        <template #option="{ option }">
          <span>{{ printVakLabel(option) }}</span>
        </template>
        <template #maxElements>
          Je kunt maximaal {{ maxVakken }} {{ maxVakken > 1 ? 'vakken' : 'vak' }} volgen in deze cursusreeks.
        </template>
        <template #noResult>
          Geen vak(ken) gevonden met deze naam.
        </template>
      </Multiselect>
    </ValidatedFormField>
    <template v-if="ingeschrevenVakken.length > 0">
      <h3 class="is-size-4">
        Vakken waarvoor je al ingeschreven bent
      </h3>
      <span
        v-for="vak in ingeschrevenVakken"
        :key="`naam-ingeschreven-${vak.Vak_ID}`"
        class="multiselect__tag no-icon"
        v-text="vak.naam"
      />
    </template>
    <h3
      v-if="Object.keys(data.cijfers).length > 0"
      class="is-size-4"
    >
      Cijfers
      <SslInfoPopover title="Cijfer">
        <p>Vul hier je gemiddelde SE-cijfer tot nu toe in, afgerond op één decimaal.</p>
      </SslInfoPopover>
    </h3>
    <SslCijferField
      v-for="vak in data.vakken"
      :key="`cijfer-${vak.Vak_ID}`"
      v-model="data.cijfers[vak.Vak_ID]"
      :track-by="vak.Vak_ID"
      :vak="vak"
      :validator="$v.data.cijfers.$each.$iter[vak.Vak_ID]"
      @input="() => $v.data.cijfers.$each[vak.Vak_ID].$touch()"
    />
  </div>
</template>

<script>
  import {computed, del as deleteReactive, set as setReactive} from 'vue';
  import Multiselect from 'vue-multiselect';
  import {between, required} from 'vuelidate/lib/validators';

  import SslCijferField from '../elements/SslCijferField.vue';
  import SslInfoPopover from '../elements/SslInfoPopover.ts.vue';
  import ValidatedFormField from '../elements/ValidatedFormField.vue';

  import {eenDecimaal} from '../validation/index';

  import useVakkenoverzicht, {useVakkenoverzichtProps} from '@/js/vue/util/vakkenoverzicht';

  const elkVakCijfer = function(vakken) {
    for (const vak of vakken) {
      if (!(vak.Vak_ID in this.data.cijfers) || this.data.cijfers[vak.Vak_ID] === null) {
        return false;
      }
    }
    return true;
  };

  const minimumOne = function(value) {
    return (value.length >= 1);
  };

  export default {
    name: 'PartialFormVakken',
    components: {
      Multiselect,
      SslCijferField,
      SslInfoPopover,
      ValidatedFormField,
    },
    props: {
      data: {
        type: Object,
        required: true,
      },
      ...useVakkenoverzichtProps(),
    },
    setup(props) {
      const ingeschrevenVakken = computed(() => props.data.vakken.filter((vak) => vak.ingeschreven));
      const heeftInschrijving = (vak) => ingeschrevenVakken.value.find((inschrijving) =>
        (inschrijving.Vak_ID === vak.Vak_ID));

      const {
        cursussenPerVak,
        periodes,
      } = useVakkenoverzicht(props);

      const beschikbareVakken = computed(() => cursussenPerVak.value.map((cursusVak) => ({
        ...cursusVak,
        $isDisabled: Boolean(!cursusVak.open || heeftInschrijving(cursusVak)),
      })));

      const maxVakken = computed(() => periodes.value.length);

      return {
        beschikbareVakken,
        ingeschrevenVakken,
        maxVakken,
        heeftInschrijving,
      };
    },
    watch: {
      'data.vakken': {
        handler: function(vakken) {
          vakken.forEach((vak) => {
            if (this.data.cijfers[vak.Vak_ID] === undefined) {
              setReactive(this.data.cijfers, vak.Vak_ID, '');
            }
          });
        },
        immediate: true,
      },
    },
    methods: {
      verwijderVak(vak) {
        Object.keys(this.data.indelingen).forEach((periode) => {
          if (this.data.indelingen[periode] === vak.Vak_ID) {
            deleteReactive(this.data.indelingen, periode);
          }
        });
        deleteReactive(this.data.cijfers, vak.Vak_ID);
      },
      tagClass(vak) {
        return {
          'multiselect__tag': true,
          'ingeschreven': this.heeftInschrijving(vak),
        };
      },
      printVakLabel(vak) {
        return vak.naam + (
          this.heeftInschrijving(vak)
            ? ' (al ingeschreven)'
            : (!vak.open ? ' (alle periodes volgeboekt)' : '')
        );
      },
    },
    validations: {
      data: {
        vakken: {
          minimumOne,
          elkVakCijfer,
        },
        cijfers: {
          $each: {
            required,
            between: between(1, 10),
            eenDecimaal,
          },
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  @use "@/sass/variables";

  :deep(.multiselect__tag) { /* stylelint-disable-line selector-class-pattern */
    background-color: variables.$ssl-green;

    &.ingeschreven {
      display: none;
    }

    &.no-icon {
      padding: 4px 10px;
    }
  }

  :deep(.multiselect__tag-icon) { /* stylelint-disable-line selector-class-pattern */
    &::after {
      color: variables.$white;
    }

    &:hover {
      background: rgba(variables.$black, 0.3);
    }
  }
</style>
