// Based on https://dev.to/razi91/writing-a-composable-function-to-fetch-data-from-rest-api-in-vue-js-4957

import {
  computed,
  reactive,
  ref,
  unref,
  watch,
  isRef,
  toRefs,
  type Ref,
} from 'vue';

export default function useGetRequest<T>(
  url: string | Ref<string>,
  initial: T,
  enabled = ref(true),
) {
  const result = ref(initial) as Ref<T>;
  const state = reactive({
    isLoading: false,
    error: null,
  });
  const hasError = computed(() => state.error !== null);

  const reload = async() => {
    if (!enabled.value) {
      return;
    }
    state.error = null;
    state.isLoading = true;
    $http.get<T>(unref(url))
      .then((response) => result.value = response.data)
      .catch((error) => state.error = error)
      .finally(() => state.isLoading = false);
  };

  if (isRef(url)) {
    watch(url, reload);
  }
  reload();

  return {
    ...toRefs(state),
    hasError,
    result,
    reload,
  };
}
