<template>
  <div class="vrienden-overzicht">
    <h2 class="is-size-2">
      Vrienden
    </h2>
    <LazyLoadingWrapper
      :loading="isLoading"
      :has-error="hasError"
      class="content"
    >
      <div v-if="groepsgenoten.length > 0">
        <p>Op dit moment zit je in een groep met:</p>
        <ul>
          <li
            v-for="groepsgenoot in groepsgenoten"
            :key="groepsgenoot"
          >
            {{ groepsgenoot }}
          </li>
        </ul>
        <BButton
          type="is-danger"
          icon-left="delete"
          :loading="verlaatButtonLoading"
          @click="verlaatClick"
        >
          Groep verlaten
        </BButton>
      </div>
      <div v-else>
        <p>Op dit moment zit je niet in een groep.</p>
      </div>
      <h3 class="is-size-4">
        Uitnodigen
        <SslInfoPopover title="Vrienden uitnodigen">
          Gebruik de onderstaande knoppen om je persoonlijke link te delen met je vriend(in).
          Als hij/zij op deze link klikt, zullen jullie samen in een groep worden geplaatst.<br />
          Je kunt met maximaal {{ maxGrootte }} personen in een groepje zitten.<br />
          <strong>Let op:</strong> alleen als je uiterlijk twee dagen voor aanvang de cursus je vriend(in) uitnodigt
          kunnen we rekening houden met je voorkeuren.
        </SslInfoPopover>
      </h3>
      <p v-if="groepIsVol">
        Je groep is vol.
      </p>
      <template v-else>
        <BButton
          v-if="gebruikWebShareApi"
          type="is-success"
          icon-left="share"
          @click="shareClick"
        >
          Uitnodigen
        </BButton>
        <template v-else>
          <ShareNetwork
            v-for="(network, key) in shareNetworks"
            :key="key"
            :network="network.name"
            :url="uitnodigingsUrl"
            :title="shareTitle"
            :description="shareDescription"
          >
            <span
              class="social-icon mdi"
              :class="`mdi-${network.icon.name} has-text-${network.icon.color}`"
            />
          </ShareNetwork>
          <span
            class="social-icon copy-icon mdi mdi-link has-text-primary"
            @click="copyUrlClick"
          />
          <!-- I've added a key here, because else for some weird re Vue gets confused on render,
            does not add the correct data-attribute and scoped css is not applied -->
          <div key="_hack" class="invite-url-text">
            <span><strong>Jouw persoonlijke link</strong></span><br />
            <span v-text="uitnodigingsUrl" />
          </div>
        </template>
      </template>
    </LazyLoadingWrapper>
  </div>
</template>

<script setup lang="ts">
  import BButton from 'buefy/src/components/button/Button.vue';
  import {ref, computed, watch, getCurrentInstance} from 'vue';
  // @ts-expect-error Package is not TypeScript compatible, but should be replaced anyways (see #283)
  import {ShareNetwork} from 'vue-social-sharing';

  import SslInfoPopover from '../elements/SslInfoPopover.ts.vue';
  import LazyLoadingWrapper from '../wrappers/LazyLoadingWrapper.ts.vue';

  import {type User} from '@/js/types/models/algemeen';
  import {copyToClipboard} from '@/js/util/clipboard';
  import useGetRequest from '@/js/vue/util/getRequest';
  import usePostRequest from '@/js/vue/util/postRequest';

  // See VriendenController@index
  type Groepsgenoten = Array<User['volledigeNaam']>;
  interface VriendenEndpointResponse {
    uitnodigingsUrl: string;
    verlaatGroepUrl: string;
    groep: Groepsgenoten;
    maxGrootte: number;
  }

  const gebruikWebShareApi = window.navigator?.share !== undefined;

  const shareTitle = 'Samen examentraining volgen bij Stichting Studiebegeleiding Leiden (SSL)?';
  const shareDescription = 'Wil je samen met mij een examentraining bij SSL volgen?'
    + ' Gebruik deze link om samen met mij in een groep ingedeeld te worden.';
  const shareNetworks = [
    {
      name: 'whatsapp',
      icon: {
        name: 'whatsapp',
        color: 'success',
      },
    },
    {
      name: 'email',
      icon: {
        name: 'email',
        color: 'dark',
      },
    },
    {
      name: 'sms',
      icon: {
        name: 'message-processing',
        color: 'dark',
      },
    },
  ];

  interface Props {
    vriendenGroepEndpoint: string;
  }
  const props = defineProps<Props>();

  const app = getCurrentInstance();
  const $buefy = app?.proxy?.$root?.$buefy;

  const {
    isLoading,
    hasError,
    result, reload,
  } = useGetRequest<VriendenEndpointResponse>(computed(() => props.vriendenGroepEndpoint), {
    uitnodigingsUrl: '',
    verlaatGroepUrl: '',
    maxGrootte: 0,
    groep: [],
  });
  const uitnodigingsUrl = computed(() => result.value.uitnodigingsUrl);
  const verlaatGroepUrl = computed(() => result.value.verlaatGroepUrl);
  const maxGrootte = computed(() => result.value.maxGrootte);

  // Groepsgenoten should be writable, as leaving group will force-clear it
  const groepsgenoten = ref<Groepsgenoten>([]);
  watch(result, (newVal) => groepsgenoten.value = newVal.groep);

  const groepIsVol = computed(() => groepsgenoten.value.length + 1 >= maxGrootte.value);

  const copyUrlClick = async() => {
    copyToClipboard(uitnodigingsUrl.value)
      .then(() => {
        $buefy?.toast.open({
          duration: 3000,
          message: 'Link succesvol gekopieerd',
          type: 'is-info',
          position: 'is-bottom',
        });
      })
      .catch(() => {
        $buefy?.toast.open({
          duration: 3000,
          message: 'Link kopiëren mislukt; gebruik één van de andere opties of kopieer de link handmatig.',
          type: 'is-warning',
          position: 'is-bottom',
        });
      });
  };

  const shareClick = () => navigator.share({
    title: shareTitle,
    text: shareDescription,
    url: uitnodigingsUrl.value,
  });

  const {
    isSaving,
    postRequest,
    postRequestErrorTitle,
  } = usePostRequest(verlaatGroepUrl, () => groepsgenoten.value = [], () => {/* do nothing*/}, () => reload());
  postRequestErrorTitle.value = 'Fout opgetreden bij het verlaten van de groep';

  const verlaatGroep = () => postRequest({});
  const verlaatClick = () => {
    $buefy?.dialog.confirm({
      title: 'Groep verlaten',
      message: 'Weet je zeker dat je deze groep wilt verlaten?',
      type: 'is-warning',
      hasIcon: true,
      confirmText: 'OK',
      cancelText: 'Annuleren',
      onConfirm: () => verlaatGroep(),
    });
  };

  const verlaatButtonLoading = computed(() => isSaving.value);
</script>

<style lang="scss" scoped>
  @use "@/sass/variables";

  .social-icon {
    color: variables.$black;
    height: 30px;
    width: 30px;
    font-size: 30px;
    margin: 5px;
  }

  .copy-icon {
    cursor: pointer;
  }

  .invite-url-text {
    font-size: 0.9rem;
    padding: 0.2rem;
  }
</style>
