import {ref} from 'vue';

import useEventListener from './eventListener';

export default function useBeforeUnload(enabled = ref(true)) {
  const eventValue = ref('Niet al je gegevens zijn opgeslagen. Weet je zeker dat je de pagina wilt verlaten?');
  const beforeUnloadHandler = (event: BeforeUnloadEvent) => {
    if (!enabled.value) {
      return;
    }

    event.preventDefault();
    // Required for legacy support; see https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#browser_compatibility
    event.returnValue = eventValue.value; // eslint-disable-line no-param-reassign
  };

  const {
    addEventListener: addBeforeUnloadHandler,
    removeEventListener: removeBeforeUnloadHandler,
  } = useEventListener('beforeunload', beforeUnloadHandler);

  return {
    eventValue,
    addBeforeUnloadHandler,
    removeBeforeUnloadHandler,
  };
}
