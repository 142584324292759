<template>
  <section>
    <SslDropdown
      v-model="model"
      label="Voorkeursperiode"
      :hidden="hidden"
      placeholder="Geen voorkeur"
      :options="voorkeurOpties"
      @input="updateInput"
    />
    <BMessage
      title="Waarschuwing"
      :active.sync="wachtlijst"
      type="is-warning"
      has-icon
      closable
    >
      Let op: voor jouw vak is er in deze periode een wachtlijst.
      <SslInfoPopover title="Wachtlijst">
        <p>
          Lees <a
            href="https://www.sslleiden.nl/FAQ#indelingwachtlijst"
            target="_blank"
          >hier</a> wat er gebeurt als je op een wachtlijst staat.
        </p>
      </SslInfoPopover><br />
      Je kunt je opgeven voor een wachtlijst, maar de kans is klein dat we je in deze periode in kunnen delen.
    </BMessage>
  </section>
</template>

<script>
  import {BMessage} from 'buefy/src/components/message';
  import {computed, ref} from 'vue';

  import SslDropdown from '../elements/SslDropdown.vue';
  import SslInfoPopover from '../elements/SslInfoPopover.ts.vue';
  import sslDateFormatter from '../mixins/SslDateFormatter';

  import useVakkenoverzicht, {useVakkenoverzichtProps} from '@/js/vue/util/vakkenoverzicht';

  export default {
    name: 'FieldVoorkeursperiode',
    components: {
      BMessage,
      SslDropdown,
      SslInfoPopover,
    },
    mixins: [
      sslDateFormatter,
    ],
    props: {
      value: {
        validator: (prop) => (typeof prop === 'number' || prop === null),
        required: true,
      },
      vak: {
        type: Object,
        required: true,
      },
      beschikbaarheid: {
        type: Object,
        required: true,
      },
      ...useVakkenoverzichtProps(),
    },
    setup(props) {
      const model = ref(null); // eslint-disable-line vue/require-typed-ref -- Remove when migrating to TypeScript
      const wachtlijst = ref(false);

      const {getOpenCursussenForVak} = useVakkenoverzicht(props);

      const openCursussen = computed(() => getOpenCursussenForVak(props.vak));
      const beschikbareCursussen = computed(() => openCursussen.value
        .filter((cursus) => props.beschikbaarheid[cursus.Periode_ID] === '1')
        .sort((a, b) => a.dagen[0] > b.dagen[0] ? 1 : -1));

      const geenVoorkeurOptie = {value: null, wachtlijst: false, text: 'Geen voorkeur'};

      return {
        model,
        wachtlijst,
        beschikbareCursussen,
        geenVoorkeurOptie,
      };
    },
    computed: {
      voorkeurCursusOpties() {
        return this.beschikbareCursussen
          .map((cursus) => {
            const cursusDagen = cursus.dagen.filter((dag) => !cursus.nietopdagen.includes(dag));
            const wachtlijst = (cursus.status === 'Wachtlijst');
            return {
              value: cursus.Periode_ID,
              wachtlijst: this.toonWachtlijst(wachtlijst),
              text: `Periode ${ cursus.naam }${ this.toonWachtlijst(wachtlijst) ? ': Wachtlijst' : ''}`
                + ` (${ cursusDagen.map((dag) => this.dagNaarDagString(dag)).join(' | ') })`,
            };
          });
      },
      voorkeurOpties() {
        return [this.geenVoorkeurOptie, ...this.voorkeurCursusOpties];
      },
      hidden() {
        // verbergen als er max. 1 cursusoptie is en geen voorkeur is ingesteld
        return this.voorkeurCursusOpties.length <= 1 && (this.value === null);
      },
    },
    watch: {
      model: {
        immediate: true,
        handler(newVal) {
          this.wachtlijst = newVal && this.voorkeurCursusOpties.find((periode) => periode.value === newVal).wachtlijst;
        },
      },
      value: {
        immediate: true,
        handler(newVal) {
          this.model = newVal;
        },
      },
    },
    methods: {
      toonWachtlijst(heeftWachtlijst) {
        // We tonen dat er een wachtlijst is als er een wachtlijst is EN de leerling niet al elders ingedeeld is voor dit vak
        return heeftWachtlijst && !(this.vak.ingeschreven && !this.vak.wachtlijst);
      },
      updateInput() {
        this.$emit('input', this.model);
      },
    },
  };
</script>
