<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">
        Vak kiezen
      </p>
    </header>
    <section class="modal-card-body">
      Selecteer het vak dat je wilt volgen in de onderstaande dropdown.
      <ValidatedFormField
        :validator="$v.data.vak"
        @input="() => $v.data.vak.$touch()"
      >
        <Multiselect
          v-model="data.vak"
          label="naam"
          track-by="naam"
          :options="beschikbareVakken"
          :multiple="false"
          :allow-empty="false"
          close-on-select
          placeholder="Kies hier je vak"
          select-label="Kies vak"
          selected-label="Gekozen"
          deselect-label="Gekozen"
          @select="selectVak"
        >
          <template #noOptions>
            Vakken worden geladen...
          </template>
          <template #maxElements>
            Je kunt maximaal 1 vak volgen in deze cursusreeks.
          </template>
          <template #noResult>
            Geen vak(ken) gevonden met deze naam.
          </template>
          <template #option="option">
            <span :class="vakLabelClass(option.option)"> {{ vakLabelText(option.option) }} </span>
          </template>
        </Multiselect>
      </ValidatedFormField>
      <BMessage
        v-if="heeftWachtlijst()"
        title="Waarschuwing"
        type="is-warning"
        has-icon
        :closable="false"
      >
        Let op: voor deze periode is er een wachtlijst.
        <SslInfoPopover title="Wachtlijst">
          <p>
            Lees <a
              href="https://www.sslleiden.nl/FAQ#indelingwachtlijst"
              target="_blank"
            >hier</a> wat er gebeurt als je op een wachtlijst staat.
          </p>
        </SslInfoPopover><br />
        Je kunt je opgeven voor een wachtlijst, maar de kans is klein dat we je in kunnen delen.
      </BMessage>
      <template v-if="data.vak.naam">
        <h4 class="is-size-4">
          Cijfer
          <SslInfoPopover title="Cijfer">
            <p>Vul hier je gemiddelde SE-cijfer tot nu toe in, afgerond op één decimaal.</p>
          </SslInfoPopover>
        </h4>
        <SslCijferField
          v-model="data.Cijfer"
          :vak="data.vak"
          :validator="$v.data.Cijfer"
          @input="() => $v.data.Cijfer.$touch()"
        />
      </template>
    </section>
    <footer class="modal-card-foot">
      <button
        class="button is-right is-danger"
        type="button"
        @click="() => $parent.close()"
      >
        Annuleren
      </button>
      <button
        class="button is-right is-success"
        type="button"
        @click="opslaanClick"
      >
        Opslaan
      </button>
    </footer>
  </div>
</template>

<script>
  import {BMessage} from 'buefy/src/components/message';
  import {computed, ref} from 'vue';
  import Multiselect from 'vue-multiselect';
  import {between, required} from 'vuelidate/lib/validators';

  import SslCijferField from '../elements/SslCijferField.vue';
  import SslInfoPopover from '../elements/SslInfoPopover.ts.vue';
  import ValidatedFormField from '../elements/ValidatedFormField.vue';

  import {eenDecimaal} from '../validation/index';

  import usePostRequest from '@/js/vue/util/postRequest';

  export default {
    name: 'SslVakKiezer',
    components: {
      BMessage,
      Multiselect,
      SslCijferField,
      SslInfoPopover,
      ValidatedFormField,
    },
    props: {
      cursusreeks: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const postEndpoint = computed(() => `/api/wijzigen/wijzig_vak/${props.cursusreeks.slug}`);
      const handlePostResponse = ref();
      const {
        isSaving,
        postRequest,
        postRequestErrorTitle,
      } = usePostRequest(postEndpoint, handlePostResponse);
      postRequestErrorTitle.value = 'Fout opgetreden bij wijzigen vak';

      return {
        isSaving,
        handlePostResponse,
        postRequest,
      };
    },
    data() {
      return {
        data: {
          vak: {},
          Cijfer: '',
        },
        vakkenOverzicht: [],
      };
    },
    computed: {
      formData() {
        return {
          'Vak_ID': this.data.vak.Vak_ID,
          'Cijfer': this.data.Cijfer,
        };
      },
      beschikbareVakken() {
        let arr = [];
        if (this.vakkenOverzicht.length > 0) {
          arr = this.vakkenOverzicht.map((vak) => {
            vak.$isDisabled = !vak.open;
            vak.status = Object.values(vak.periodes)[0].status;
            return vak;
          });
        }
        return arr;
      },
    },
    mounted() {
      this.handlePostResponse = this.onPostRequestSucces; // deduplicate this on full setup migration
      $http.get('/api/overzicht/' + this.cursusreeks.slug + '/cursussen')
        .then((response) => {
          this.vakkenOverzicht = response.data;
        });
    },
    methods: {
      checkIfFormValid() {
        this.$v.$touch();
        return !(this.$v.$invalid);
      },
      heeftWachtlijst() {
        return (this.data.vak && this.data.vak.status === 'Wachtlijst');
      },
      vakLabelClass(vak) {
        if (vak && vak.status === 'Wachtlijst') {
          return ['is-wachtlijst'];
        }
        return '';
      },
      vakLabelText(vak) {
        if (vak) {
          let res = vak.naam;
          if (vak.status === 'Wachtlijst') {
            res = res + ' (wachtlijst)';
          } else if (vak.status === 'Gesloten') {
            res = res + ' (inschrijvingen gesloten)';
          }
          return res;
        }
        return null;

      },
      selectVak() {
        this.data.Cijfer = '';
      },
      opslaanClick() {
        if (this.checkIfFormValid() && !this.isSaving) {
          this.$buefy.dialog.confirm({
            title: 'Bevestig wijziging vak',
            type: 'is-warning',
            message: 'Weet je zeker dat je je vak wilt wijzigen?<br />'
              + '<strong>Let op!</strong> Je kunt deze wijziging niet ongedaan maken.<br />',
            onConfirm: this.opslaan,
            cancelText: 'Annuleren',
            confirmText: 'Vak wijzigen',
          });
        }
      },
      opslaan() {
        this.postRequest(this.formData);
      },
      onPostRequestSucces() {
        this.$emit('save');
        this.$parent.close();
      },
    },
    validations: {
      data: {
        vak: {
          required,
        },
        Cijfer: {
          required,
          between: between(1, 10),
          eenDecimaal,
        },
      },
    },
  };
</script>
