import {type TISODate} from '@/js/util/dates';

export enum CursusStatus {
  Open = 'Open',
  Wachtlijst = 'Wachtlijst',
  Gesloten = 'Gesloten',
}
export type VakNiveau = 'havo' | 'vwo';

export interface Vak {
  Vak_ID: number;
  niveau: VakNiveau;
  code: string;
  naam: string;
}

export interface Periode {
  Periode_ID: number;
  naam: string;
  dagen: Array<TISODate>;
  opmerkingen: string | null;
}

export interface Cursus {
  Periode_ID: number;
  naam: string;
  dagen: Array<TISODate>;
  status: CursusStatus;
  ondervoorbehoud: boolean;
  opmerkingen: string | null; // cursus-opmerkingen
  nietopdagen: Array<TISODate>;
}

export interface CursusVak extends Vak {
  open: boolean;
  periodes: Record<Periode['Periode_ID'], Cursus>;
}

export interface VakkenOverzicht {
  cursussen: Record<VakNiveau, Array<CursusVak>>;
  periodes: Array<Periode>;
}
