<template>
  <ValidatedFormField
    :label="label"
    :validator="validator"
  >
    <BField class="is-grouped">
      <SslDropdown
        v-model="keuze.id"
        placeholder="Kies één van de opties"
        :options="optionsWithAlternative"
        label=""
        :validator="validator.id"
        @input="updateInput"
      />
      <SslTextField
        v-if="keuze.id === alternativeOption.value"
        v-model="keuze.anders"
        label=""
        :validator="validator.anders"
        @input="updateInputAnders"
      />
    </BField>
  </ValidatedFormField>
</template>

<script>
  import BField from 'buefy/src/components/field/Field.vue';

  import SslDropdown from './SslDropdown.vue';
  import SslTextField from './SslTextField.vue';
  import ValidatedFormField from './ValidatedFormField.vue';

  export default {
    name: 'SslDropdownAlternative',
    components: {
      BField,
      SslDropdown,
      SslTextField,
      ValidatedFormField,
    },
    props: {
      options: {
        type: Array,
        required: true,
      },
      alternativeOption: {
        type: Object,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      validator: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        keuze: {
          id: undefined,
          anders: '',
        },
      };
    },
    computed: {
      optionsWithAlternative: function() {
        const res = this.options;
        res.push(this.alternativeOption);
        return res;
      },
    },
    methods: {
      updateInput() {
        this.$emit('input', this.keuze);
      },
      updateInputAnders() {
        this.updateInput();
        this.validator.anders.$touch();
      },
    },

  };
</script>
